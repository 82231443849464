SIZE = 256
LAYER_HEIGHT = 64
DEFAULT_VERTEX_SHADER = ""

layers = null
overlayer = null
selected_layer = null
hammer = null

canvas_container = null
container = null
header_background = null


page = null
current_page_index = 0
current_page = null

bouncer = null
middle = null

clock = 0

toner = new Toner()

p5.disableFriendlyErrors = true

root_style = getComputedStyle(document.body)
for k, v of Colors
	Colors[k] = root_style.getPropertyValue("--#{k}")

screen_orientation = ""

font = null

connect_hammer = (element, host) ->
	hammer = new Hammer.Manager element

	hammer.add new Hammer.Pan(direction: Hammer.DIRECTION_ALL, threshold: 0 )
	hammer.add new Hammer.Tap()
	hammer.add new Hammer.Press()

	hammer.on "pan", host.pan.bind host
	hammer.on "panstart", host.interact.bind host
	hammer.on "panend", host.interact.bind host
	hammer.on "press", host.interact.bind host
	hammer.on "pressup", host.interact.bind host
	hammer.on "tap", host.interact.bind host

set_background = (a, b) ->
	back = document.getElementById("bg_gradient")
	back.style.background = "linear-gradient(180deg, var(--#{a}) 0%, var(--#{b}) 100%)";
	back = document.getElementById("header_background")
	back.style.background = "linear-gradient(180deg, var(--#{b}) 0%, var(--white) 100%)";


init_input = () ->
	# document.addEventListener "wheel", (e) -> overlayer.step_selected(Math.sign(e.deltaY))

preload = () ->
	DEFAULT_VERTEX_SHADER = loadStrings "src/default.vert"
	font = loadFont "assets/font/fixedfixedsys.ttf"
	overlayer = new OverLayer(SIZE)
	overlayer.preload()

	layers =
		topograph : new Topograph SIZE
		flow : new Flow SIZE
		tile: new Tile SIZE
		goal: new Goal SIZE
		spawn: new Spawn SIZE
		clouds : new Clouds SIZE
		game: new Game SIZE

	for k, v of layers
		layers[k].preload()



#load_into = (element, src) ->
#	fetch(src)
#		.then (response) -> response.text()
#		.then (html) -> setInnerHTML(element, html);

load_page = (p) ->
	$(page.container.elt).load p.source
#	load_into page.container.elt, p.source
  

in_viewport = (element) ->
	r = element.getBoundingClientRect()
	collideRectRect 0,0,innerWidth, innerHeight, r.x, r.y, r.width, r.height

# visible_layer = () ->
# 	for k, v of layers
# 		if in_viewport v.container.parent()
# 			select_layer k
# 			return v
# 	null

scroll = (e) ->
	if screen_orientation is "landscape"
		sy = window.scrollY
		# console.log sy
		# page.header.style "top", "#{(innerHeight * 0.5 - rem_pixel(3)) + sy * 0.01}px"

new_element = (type, options) ->
	console.log options
	d =
		switch type
			when "div" then createDiv()
			when "img" then createImg(options.src)
	for k, v of options
		switch k
			when "css"
				for key, value of v
					d.style key, value
			when "click"
				d.elt.addEventListener "click", v
			else
				if typeof d[k] is "function"
					d[k] v
	d


create_page = (container) ->
	page =
		header : new_element("div"
			class : "header",
			id : "header",
			# css :
			# 	background : "#f00"
			parent : container
		)
		container : new_element("div"
			parent : container
		)
		main_container : container
	header_background = new_element("div",
		id : "header_background"
		parent: page.header
	)



build_page = () ->
	container = new_element("div"
		id : "container"
	)
	document.addEventListener "scroll", scroll

	create_page container

build_menu = () ->
	menu = document.getElementById("menu")
	left = new_element("div"
		html : "<"
		id : "left_menu_button"
		class : "menu_button"
		click : (e) ->
			step_page -1
	)
	# <img src="./assets/logo/logo.svg" id="menu-logo"></img>

	# logo = createImg("./assets/logo/logo.svg")
	# logo.id "menu-logo"
	# logo.class "menu_button"


	middle = new_element("div"
		html : ""
		id : "middle_menu_button"
		class : "menu_button"
		click : (e) ->
			scroll_top(true)
			overlayer.reset_layer()
	)


	right = new_element("div"
		html : ">"
		id : "right_menu_button"
		class : "menu_button"
		click : (e) ->
			step_page 1
	)

	menu.appendChild left.elt
	menu.appendChild middle.elt
	menu.appendChild right.elt
	# for p in Pages
	# 	mb = new_element("div"
	# 		html : p.title
	# 		id : "page_button_" + p.title
	# 		class : "menu_button"
	# 		click : (e) ->
	# 			title = e.target.id.split("_button_")[1]
	# 			open_page Pages.find((p) -> p.title is title)
	# 	)
	# 	menu.appendChild mb.elt

get_hash_page = () ->
	id = window.location.hash
	p =
		if id.length > 1
			Pages.find((_p) -> id.substr(1) is _p.id)
		else
			null
	if p?
		p
	else
		current_page_index = 0
		Pages[current_page_index]

setup = () ->
	# document.title = Title

	build_menu()
	build_page()
	bouncer = new Bouncer()
	overlayer.init layers, page.header.elt
	overlayer.canvas = document.getElementById("defaultCanvas0")
	page.header.elt.appendChild overlayer.canvas

	connect_hammer overlayer.canvas, overlayer

	open_page get_hash_page()

	init_input()
	window.addEventListener "hashchange", (e) ->
		if e.newURL isnt e.oldURL
			p = get_hash_page()
			open_page p


	# window.addEventListener "scroll"



draw = () ->
	dt = deltaTime * 0.001
	clock = (clock + dt)
	clock -= Math.floor clock
	overlayer.draw(dt, clock)
	bouncer.update(dt)

open_page_by_id = (n) ->
	p = Pages.find((_p) -> _p.id is n)
	if p?
		open_page p

open_page = (p) ->
	if not current_page? or current_page.title isnt p.title
		current_page = p
		set_background p.colors.a, p.colors.b
		middle.html current_page.title
		current_page_index = Pages.findIndex (page) -> page.title is p.title
		window.location.hash = p.id
		overlayer.select_layer p.layer
		load_page p
		resize_page()
		scroll_top(current_page.id is "verseny")


step_page = (d) ->
	toner.start()
	current_page_index += d
	if current_page_index < 0
		current_page_index = Pages.length - 2
	else
		current_page_index = current_page_index % (Pages.length - 1)

	open_page Pages[current_page_index]

windowResized = () ->
	resize_page()

mouseMoved = () ->
	x = mouseX
	y = mouseY

rem_pixel = (rem) ->
	rpx = rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
	# console.log rpx
	rpx

scroll_top = (forced_y) ->
	if screen_orientation is "landscape"
		# console.log "landscroll"
		window.scrollTo({
			top : 0
			left : 0
			behavior : 'smooth'
		})
		# window.scrollTo 0 0
	else
		setTimeout(
			() ->
				# console.log "portscroll"
				window.scrollTo(
					top:
						if forced_y or window.scrollY >= innerWidth / 3.0
							innerWidth / 1.5
						else
							0
					left: 0
					behavior: 'smooth'
				)
			100
		)
		# window.scrollTo 0, innerWidth / 1.5
		# else

resize_page = () ->
	ratio = innerWidth / innerHeight
	c = document.getElementById "defaultCanvas0"


	new_orientation = if ratio > 1.3 then "landscape" else "portrait"

	if new_orientation isnt screen_orientation
		screen_orientation = new_orientation
		if screen_orientation is "landscape"
			page.header.class "floater"
			container.class ""
		else
			page.header.class "header"
			container.class "mobile-container"

	scroll_top()

	if screen_orientation is "landscape"
		w = container.size().width
		ws = innerWidth - w
		floater_h = innerHeight * 0.7
		margin = "0"
		free_space = innerWidth - (w + innerHeight)

		if free_space > 0
			margin = (free_space * 0.5) + "px"
		else
			floater_h = ws * 0.7

		page.header.style "width", "#{floater_h}px"
		page.header.style "height", "#{floater_h}px"
		page.container.style "padding-left", ""
		page.container.style "padding-right", ""
		page.container.style "width", ""
		container.style "margin-left", "#{margin}"
		bouncer.show true
		header_background.hide()

	else # portrai mode
		full_row = rem_pixel(40)
		if full_row + rem_pixel(3) < innerWidth
			pad = ((innerWidth - full_row) / 2) + "px"
			page.container.style "padding-left", pad
			page.container.style "padding-right", pad
			page.container.style "width", "#{full_row}px"
		else
			pad = (((innerWidth - full_row) * 0.5)) * 0.5 + "px"
			page.container.style "padding-left", "1rem"
			page.container.style "padding-right", "1rem"
			page.container.style "width", ""


		page.header.style "top", ""
		page.header.style "width", ""
		page.header.style "height", ""
		bouncer.show false
		container.style "margin-left", ""
		header_background.show()

keyPressed = (e) ->
	target = e.target || e.srcElement
	if (!/INPUT|TEXTAREA|SELECT|BUTTON/.test(target.nodeName))
		switch e.key
			when "d"
				debug = not debug
				localStorage.setItem "debug", debug

			when "ArrowLeft"
				step_page -1
			when "ArrowRight"
				step_page 1


class Bouncer
	speed : 100
	dir :
		x : 1
		y : 1
	last_pos :
		x : 0
		y : 0
	last_scroll : 0
	logo_index : 0
	constructor: () ->
		@frame = 0
		@element_x = document.getElementById("bouncer")
		@element_x.addEventListener "click", @step_logo.bind @
		@element_y = @element_x.children[0]
		@logo = @element_y.children[0]

		@set_logo Math.floor(Math.random() * Logos.length)
		# @css = getComputedStyle(document.body)
		document.body.appendChild @element_x
	get_pos : () ->
		tx = window.getComputedStyle(@element_x).getPropertyValue("transform")
		tx = tx.split(",")
		ty = window.getComputedStyle(@element_y).getPropertyValue("transform")
		ty = ty.split(",")
		createVector parseFloat(tx[4]), parseFloat(ty[5])
	step_logo : () ->
		@set_logo (@logo_index + 1) % Logos.length

	set_logo : (i) ->
		@logo_index = i
		l = Logos[@logo_index]
		@logo.style.top = "#{50 + 100 * l.y}%"
		@logo.style.width = "#{l.scale * 180}px"
		@logo.src = l.source()

		# root_style.setPropertyValue("--x-speed", "19s")
		# window.getComputedStyle(document.body).style.setPropertyValue("--x-speed", "19s")
	show : (visible) ->
		@element_x.style.display = (if visible then "block" else "none")

	update : (delta) ->
		p = @get_pos()

		dx = if p.x < @last_pos.x then -1 else 1
		dy = if p.y < @last_pos.y then -1 else 1

		if @dir.x isnt dx or @dir.y isnt  dy
			@dir.x = dx
			@dir.y = dy
			@step_logo()



		@last_pos = p
		@frame += 1
		if @frame > 60
			@frame = 0
			sy = window.scrollY
			if sy isnt @last_scroll
				@last_scroll = sy
				@element_x.style.top = @last_scroll + "px"
				# console.log @element_x.style.top

###
TODO
- play button
- sound effects
- juice
	- goal
	- spawn restrict
	- tile
	- autopilot

minify with

uglifyjs src/config.js src/layer.js src/shader_layer.js src/goal.js src/spawn.js src/tile.js src/clouds.js src/topograph.js src/flow.js src/particle.js src/game.js src/overlayer.js src/main.js -o src/script.min.js

###
